"use client"
import Image from "next/image";
import Image404 from "@/../public/assets/images/icon/404.webp";
import Link from "next/link";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
export default function NotFound({params}) {
  const translated= useLanguageTranslations(params.locale, "pagenotfound");
  if (!translated) {
    return <Loader />;
  }
  return (
    <div className="section about-section-2 section-padding">
        <div className="container">
          <div className="about-wrap-2">
            <center>
              {" "}
              <Image src={Image404} alt="404" />
            </center>
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-md-4"></div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <p className="text">
                  {translated("STR_THE_PAGE_IS")}{" "}
                  <Link href="/">
                    <span style={{ color: "#00aeef" }} className="fw-bold">
                      {translated("STR_HOME_PAGE")}
                    </span>
                  </Link>{" "}
                  {translated("STR_VISIT_ONE")}
                </p>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4"></div>
            </div>
            <div className="links-read">
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-md-3"></div>
                <div className="col-lg-6 col-sm-6 col-md-6">
                  <div className="links">
                    <p>
                      {translated("STR_READ")}{" "}
                      <Link href="/about">{translated("STR_ABOUT")}</Link>{" "}
                      {translated("STR_SKILROCK_TECH")}{" "}
                      <Link href="/lottery-gaming-solutions">
                        {translated("STR_LOTTO_SOLU")}
                      </Link>{" "}
                      {translated("STR_AND_IGAME")}{" "}
                      <Link href="/blogs">{translated("STR_BLOG")}</Link>{" "}
                      <Link href="/contact">{translated("STR_CON")}</Link>{" "}
                      {translated("STR_OUR_TEAM")}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
